import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
// import './App.css';
import "./App.scss";

import hamburger from "./assets/gfx/hamburger.webp";
import CloseIcon from "./assets/gfx/closeIcon.svg";
import headLogo from "./assets/gfx/1.png";
import landingSneaker from "./assets/gfx/hero-shoe.webp";
import landingSneaker2 from "./assets/gfx//New2.png";
import sliderImage from "./assets/gfx/slider.webp";

import insta from "./assets/socialMedia/insta.svg";
import twitter from "./assets/socialMedia/twitter.svg";
import discord from "./assets/socialMedia/discord.svg";

import insta_noBG from "./assets/socialMedia/insta_noBG.svg";
import twitter_noBG from "./assets/socialMedia/twitter_noBG.svg";
import discord_noBG from "./assets/socialMedia/discord_noBG.svg";

import co2 from "./assets/marketplace/co2.svg";
import ATHLEISURE from "./assets/marketplace/ATHLEISURE.svg";
import canbornNeutral from "./assets/marketplace/canbornNeutral.svg";
import rocket from "./assets/marketplace/rocket.svg";

import blockChain from "./assets/Technology/blockChain.svg";
import QRAuthentication from "./assets/Technology/QRAuthentication.svg";

import Deep_Kharadi from "./assets/team member/Deep Kharadi.png";
import Ishani_Bandhopadhyay from "./assets/team member/Ishani Bandhopadhyay.png";
import Maharsh_shah from "./assets/team member/Maharsh shah.png";
import manas_nayak from "./assets/team member/manas nayak.png";
import Smit_Bhojak from "./assets/team member/Smit Bhojak.png";
import Smit_patel from "./assets/team member/Smit patel.png";
import Sumit_Singh from "./assets/team member/Sumit Singh.png";

import HeroShoes from "./assets/3d.glb";

import Sliders from "./components/Slider";
import { useState } from "react";
function App() {
  const [showReadMore, setShowReadMore] = useState(false);
  const [moblieNav, setMoblieNav] = useState(false);

  const ToggleMAnuHandler = () => {
    setMoblieNav(moblieNav => !moblieNav)
  }
  return (
    <>
      <header className="header container" style={{ position: "relative" }}>
        <div className="image_ham_container">
          <img src={headLogo} alt="the Sneaker" id="logo" />
          <div id="hamburger" onClick={ToggleMAnuHandler}>
            
           {moblieNav ?  <img src={CloseIcon} alt="menu" id="hamburger--icon" />:  <img src={hamburger} alt="menu" id="hamburger--icon" /> }
          </div>
        </div>
        {/* <nav className="navbar" id="nav">  */}
        <nav  className={moblieNav ? "forblock navbar" : "fornone"} id="nav"> 
          <ul className="nav" onClick={()=>{setMoblieNav(false)}}>
            <a href="#aboutSec">
              <li className="nav--link">About</li>
            </a>
            <a href="#raffleSec">
              <li className="nav--link">raffle</li>
            </a>
            <a href="#marketplaceSec">
              <li className="nav--link">marketplace</li>
            </a>
            <a href="#technologySec">
              <li className="nav--link">technology</li>
            </a>
            <a href="#teamSec">
              <li className="nav--link">team</li>
            </a>
          </ul>
        </nav>

        <div className="social_wallet_container">
          <div className="social_container">
            <a
              href="https://www.instagram.com/thesneakervalley00/?igshid=ZDdkNTZiNTM%3D"
              target="_blank"
            >
              <img src={insta} alt="" />
            </a>
            <a href="https://twitter.com/thesneakernft?s=08" target="_blank">
              <img src={twitter} alt="" />
            </a>
            <a href="#" target="_blank">
              <img src={discord} alt="" />
            </a>
          </div>
          {/* <button type="button" className="cta">
            + connect wallet
          </button> */}
        </div>
      </header>

      <section className="hero custom_container container" id="hero">
        <div className="type--wrapper">
          <h1 className="deathrattle--bb-font">
            Biggest <br />
            Sneaker Raffle
          </h1>
          <ul className="main_three_points">
            <li>2500 sneakers for raffle giveaways.</li>
            <li>3D NFT airdrop and physical merch drops.</li>
            <li>Exclusive accessibility to big brands at our Marketplace.</li>
          </ul>
          {/* <button type="button" className="cta">
            learn more
          </button> */}
        </div>
        <div className="gfx--wrapper">
          <img className="ghost" src={landingSneaker2} alt="" id="hero-shoe" />
          
          {/* <model-viewer
            src={HeroShoes}
            ar
            ar-modes="webxr scene-viewer quick-look"
            poster="poster.webp"
            shadow-intensity="1"
            touch-action="pan-y"
            autoplay
          ></model-viewer> */}
        </div>
      </section>

      <section>
        <Sliders />
      </section>

      <section className="WhoWeAre_section" id="aboutSec">
        <div className="container text-center ">
          <div className="text-center head_and_para custom_moba_background">
            <h1 className="deathrattle--bb-font">WHO WE ARE ?</h1>
            <p>
              "WE ARE AN UPCOMING BLOCKCHAIN-ENHANCED ATHLEISURE BASED SNEAKER
              AND APPAREL FASHION MARKETPLACE WHERE WE ARE CONTRIBUTING TOWARDS
              A MORE SUSTAINABLE AND ECOLOGICALLY BALANCED ENVIRONMENT. "
            </p>
          </div>
        </div>
      </section>

      <section className="about_raffle_section " id="raffleSec">
        <div className="container">
          <div className=" custom_row">
            <div className=" text-center for_background">
              <h1 className="deathrattle--bb-font">About raffle</h1>
              {/* <p>
                We are an upcoming blockchain-enhanced athleisure based sneaker
                and apparel fashion marketplace where we are contributing
                towards a more sustainable and ecologically balanced
                environment.
              </p> */}
              <div className="text-start question_answer_container">
                <div className="sub_question_answer_container">
                  <h6>HOW CAN WE PARTICIPATE IN THE RAFFLE?</h6>
                  <p>
                    To participate in the raffle you need to buy the TSV NFT.
                  </p>
                </div>

                <div className="sub_question_answer_container">
                  <h6>HOW MANY TSV NFTS ARE THERE?</h6>
                  <p>The total number of NFTs supply is 10000.</p>
                </div>
                <div className="sub_question_answer_container">
                  <h6>WHAT IS THE COST OF TSV NFTS?</h6>
                  <p>The cost of each NFT is 0.08 ETH</p>
                </div>
                <div className="sub_question_answer_container">
                  <h6>HOW THE WINNERS WILL BE GET SELECTED?</h6>
                  <p>
                    10000 NFT holders will be airdropped randomly the allotted
                    winning 3D NFT of sneakers or the refund amount to their
                    respective wallets after 21 days of the release of TSV NFT
                  </p>
                </div>
                <div className="sub_question_answer_container">
                  <h6>WHAT WILL GET TO THE WINNERS?</h6>
                  <p>
                    A total number of 2500 sneakers as per the rarity will be
                    given to the 2500 winners out of 10000 people. All the
                    sneakers will be shipped physically to the winners address.
                  </p>
                </div>
                <div className="sub_question_answer_container">
                  <h6>WHAT IF I DON'T GET INTO 2500 LUCKY WINNERS?</h6>
                  <p>
                    If you are not in lucky 2500 and in other 7500, you will get
                    your NFT invested amount as refund in form of Discount
                    coupons which you can redeem at our marketplace.
                  </p>
                </div>
                <div className="sub_question_answer_container">
                  <h6>WHEN WILL TSV MARKETPLACE IS GOING TO LIVE?</h6>
                  <p>
                    After release of TSV NFT, the market place will be live
                    within 45 days.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about_marketplace_section " id="marketplaceSec">
        <div className="container">
          <div className=" custom_row">
            <div className=" text-center for_background">
              <h1 className="deathrattle--bb-font">ABOUT THE MARKETPLACE</h1>
              <div className="row">
                <div className="col-lg-6 col-6" style={{ marginBottom: "3vh" }}>
                  <div className="marketplace_card_container for_background">
                    <img src={co2} alt="" />
                    <p>
                      As fast fashion is growing day by day, environmental
                      problems are also increasing respectively
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 col-6" style={{ marginBottom: "3vh" }}>
                  <div className="marketplace_card_container for_background">
                    <img src={ATHLEISURE} alt="" />
                    <p>
                      We are creating an aggregated marketplace for ATHLEISURE
                      based Sneakers and Apparel that have less impact on the
                      environment.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 col-6">
                  <div className="marketplace_card_container for_background">
                    <img src={canbornNeutral} alt="" />
                    <p>
                      Our products are eco-friendly or carbon neutral to combat
                      fast fashion's impact on the environment
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 col-6">
                  <div className="marketplace_card_container for_background">
                    <img src={rocket} alt="" />
                    <p>
                      Our marketplace is targeted to be live by 2nd APRIL,2023.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about_marketplace_section " id="technologySec">
        <div className="container">
          <div className=" custom_row">
            <div className=" text-center for_background">
              <h1 className="deathrattle--bb-font">Technology</h1>
              <div className="row" style={{ marginBottom: "5vh" }}>
                <div className="col-lg-12">
                  <div className="for_background">
                    <p className="text-start">
                      To eliminate the counterfeit issue our weapon of choice is
                      Blockchain Technology. We use NFTs and Marketplace Smart
                      Contracts on the Ethereum Chain to store every bit of data
                      from the checklist to authenticate the product. We also
                      validate the quality of materials and their certification,
                      verifying the product's true value.
                    </p>

                    <div
                      className={showReadMore ? "forblock" : "fornone"}
                      // style={{ display: showReadMore ? "block !important" : "none !important" }}
                    >
                      <p className="text-start">
                        Each product comes with a unique QR code that allows you
                        to scan and access all the details of the checklist and
                        certifications, including the materials used and where
                        it was manufactured.
                      </p>
                      <p className="text-start">
                        To make the process even more seamless for our
                        consumers, we issue Tokens representing the Sneaker
                        through Merkle airdrops for true randomness.
                        Additionally, we provide a gasless transaction using a
                        layer of Biconomy in the tech stack for your
                        convenience.
                      </p>
                    </div>
                    <div className="less_more_button_container">
                      {showReadMore ? (
                        <button
                          onClick={() => {
                            setShowReadMore(false);
                          }}
                        >
                          read less
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setShowReadMore(true);
                          }}
                        >
                          read more
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 custom_margin_bottom">
                  <div className="tech_box_container for_background">
                    <img src={blockChain} alt="" />
                    <h6>Block chain</h6>
                    <p>
                      Our Blockchain tech combats counterfeit problems by
                      storing comprehensive product data, including checklists,
                      material quality, and certification for authentication.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 custom_margin_bottom">
                  <div className="tech_box_container for_background">
                    <img src={QRAuthentication} alt="" />
                    <h6>QR Authentication</h6>
                    <p>
                      You can scan a product's QR code for comprehensive details
                      on checklists, material quality, and manufacturing
                      location
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="team_section"
        id="teamSec"
        style={{ paddingBottom: "10vh" }}
      >
        <div className="container">
          <div className="text-center team_head_para">
            <h1 className="deathrattle--bb-font">TEAM AND ADVISORS</h1>
            <p>
              We are an upcoming blockchain-enhanced athleisure based sneaker
              and apparel fashion marketplace where we are contributing towards
              a more sustainable and ecologically balanced environment.
            </p>
          </div>

          <div className="row">
            <div className="col-6 col-lg-3 text-center">
              <a
                href="https://www.linkedin.com/in/manas-ranjan-nayak-a8201052/"
                target="_blank"
              >
                <img className="img-fluid" src={manas_nayak} alt="" />
                <h6>Manas Nayak</h6>
                <p>Founder </p>
              </a>
            </div>
            <div className="col-6 col-lg-3 text-center">
              <a
                href=" https://www.linkedin.com/in/sumit-singh-2b4501aa"
                target="_blank"
              >
                <img className="img-fluid" src={Sumit_Singh} alt="" />
                <h6>Sumit Singh</h6>
                <p>Sustainability Head, Co-Founder</p>
              </a>
            </div>
            <div className="col-6 col-lg-3 text-center">
              <a
                href="https://www.linkedin.com/in/ishani-bandopadhyaya-b17614b2"
                target="_blank"
              >
                <img className="img-fluid" src={Ishani_Bandhopadhyay} alt="" />
                <h6>Ishani Bandhopadhyay</h6>
                <p>Textile and Fabric designer</p>
              </a>
            </div>
            <div className="col-6 col-lg-3 text-center">
              <a
                href="https://www.linkedin.com/in/deep-kharadi-269a65153/"
                target="_blank"
              >
                <img className="img-fluid" src={Deep_Kharadi} alt="" />
                <h6>Deep Kharadi</h6>
                <p> Blockchain Developer</p>
              </a>
            </div>
          </div>

          <div className="row custom_second_row">
            <div className="col-6 offset-lg-3 col-lg-3 text-center">
              <a
                href="https://www.linkedin.com/in/maharsh-shah-b41971170/"
                target="_blank"
              >
                <img className="img-fluid" src={Maharsh_shah} alt="" />
                <h6>Maharsh shah</h6>
                <p>Blockchain Developer</p>
              </a>
            </div>
            <div className="col-6 col-lg-3 text-center">
              <a href="https://bhojaksmith.github.io/" target="_blank">
                <img className="img-fluid" src={Smit_Bhojak} alt="" />
                <h6>Smit Bhojak</h6>
                <p>Art designer</p>
              </a>
            </div>
            {/* <div className="col-lg-3 text-center">
              <a href="https://www.instagram.com/sme.eet/" target="_blank">
                <img className="img-fluid" src={Smit_patel} alt="" />
                <h6>Smit Patel</h6>
                <p>Art designer</p>
              </a>
            </div> */}
          </div>
        </div>
      </section>

      {/* <footer>
        <div className="container footer_main_container">
          <img src={headLogo} className="footer_logo" alt="" />
          <div className="custom_footer_flex">
            <h6>Links</h6>
            <ul>
            <a href="#aboutSec">
            <li className="nav--link">About</li>
            </a>
            <a href="#raffleSec">
            <li className="nav--link">raffle</li>
            </a>
            <a href="#marketplaceSec">
            <li className="nav--link">marketplace</li>
            </a>
            <a href="#technologySec">
            <li className="nav--link">technology</li>
            </a>
            <a href="#teamSec">
            <li className="nav--link">team</li>
            </a>
            </ul>
          </div>
          <div className="custom_footer_flex">
            <h6>Other Links</h6>
            <ul>
              <li>disclaimer</li>
              <li>products</li>
              <li>technology</li>
              <li>team</li>
              <li>contact</li>
            </ul>
          </div>
          <div className="custom_footer_flex">
            <h6>Socials</h6>
            <div className="footer_socials">
              <a href="https://www.instagram.com/thesneakervalley00/?igshid=ZDdkNTZiNTM%3D" target="_blank">
                <img src={insta_noBG} alt="" />
              </a>
              <a href="https://twitter.com/thesneakernft?s=08"target="_blank">
              <img src={twitter_noBG} alt="" />
              </a>
              <img src={discord_noBG} alt="" />
            </div>
          </div>
          <button type="button" className="cta">
            + connect wallet
          </button>
        </div>
      </footer> */}
    </>
  );
}

export default App;
