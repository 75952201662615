import React from "react";
import Slider from "react-slick";
import "./one.css";
import "./two.css";
import "./slider.scss";
import NIKE_AIR_FORCE_1_X_LOUIS_VUTTON from "../assets/sneakers/NIKE AIR FORCE 1 X LOUIS VUTTON.png";
const SLIDER_ARRAY = [
  {
    index: 1,
    href: "https://stockx.com/nike-air-mag-back-to-the-future-bttf-2016",
    circle_color: "#FA9980",
    product_name: "NIKE MAG 2016",
    product_price_range: "$80K-$150K",
  },
  {
    index: 2,
    href: "https://stockx.com/nike-dunk-sb-low-staple-nyc-pigeon",
    circle_color: "#397C8B",
    product_name: "NIKE PIGEON DUNK",
    product_price_range: "$40K-$50K",
  },
  {
    index: 3,
    href: "https://stockx.com/nike-dunk-sb-low-paris",
    circle_color: "#0989BE",
    product_name: "NIKE PARIS",
    product_price_range: "$100K",
  },
  {
    index: 4,
    href: "https://stockx.com/nike-dunk-sb-low-what-the-dunk",
    circle_color: "#67BCA4",
    product_name: "NIKE SB LOW WHAT THE DUNK",
    product_price_range: "$8000-$13000",
  },
  {
    index: 5,
    href: "https://stockx.com/air-jordan-1-retro-high-off-white-chicago",
    circle_color: "#FA9980",
    product_name: "NIKE AIR X OFF-WHITE CHICAGO",
    product_price_range: "$6000-$8000",
  },
  {
    index: 6,
    href: "https://stockx.com/air-jordan-1-retro-low-dior",
    circle_color: "#397C8B",
    product_name: "NIKE AIR DIOR",
    product_price_range: "$8000-$10000",
  },
  {
    index: 7,
    href: "https://stockx.com/louis-vuitton-nike-air-force-1-low-by-virgil-abloh-white-green",
    circle_color: "#0989BE",
    product_name: "NIKE AIR FORCE 1 X LOUIS VUITTON",
    product_price_range: "$14K-$18K",
  },
  {
    index: 8,
    href: "https://stockx.com/louis-vuitton-nike-air-force-1-low-by-virgil-abloh-white-green",
    circle_color: "#67BCA4",
    product_name: "NIKE AIR X OFF WHITE UNIVERSITY BLUE",
    product_price_range: "$3000-$5000",
  },
  {
    index: 9,
    href: "https://stockx.com/air-jordan-4-retro-manila",
    circle_color: "#FA9980",
    product_name: "Nike Air Jordan 4 'Manila'",
    product_price_range: "$25000",
  },
  {
    index: 10,
    href: "https://stockx.com/air-yeezy-2-red-october",
    circle_color: "#397C8B",
    product_name: "Nike Air Yeezy 2 'Red October'",
    product_price_range: "$11000",
  },
  {
    index: 11,
    href: "https://stockx.com/louis-vuitton-nike-air-force-1-low-by-virgil-abloh-white-red",
    circle_color: "#0989BE",
    product_name: "Nike X Louis Vuitton 'White Red'",
    product_price_range: "$6500",
  },
  {
    index: 12,
    href: "https://stockx.com/adidas-human-race-nmd-pharrell-x-chanel",
    circle_color: "#67BCA4",
    product_name: "Adidas Human Race 'Pharell x Chanel'",
    product_price_range: "$6000",
  },
  {
    index: 13,
    href: "https://stockx.com/air-jordan-6-retro-oregon-ducks",
    circle_color: "#397C8B",
    product_name: "Nike Jordan 4 'Oregon Ducks'",
    product_price_range: "$5000",
  },
];
const tabletMaxWidth = 767;

function useWindowSize() {
  const [size, setSize] = React.useState([0, 0]);

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;

}


const Sliders = () => {

  const size = useWindowSize()
const width = size[0]
  // const [slidesToShow, setSlidesToShow] = React.useState(3); // width of 1 slide = 100vw/3

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: width > tabletMaxWidth ? 3 : 1 , 
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };
  return (
    <div>

      <Slider {...settings}>
        {SLIDER_ARRAY.map(slide=>{

          return          <a
            href={slide?.href}
            target="_blank"
            className={width > tabletMaxWidth ? "card_container-md-a" : "card_container-sm-a"}
          >
            <div className="card_container" data-width="640px"> 
              <div
                className={"sneaker_img_container " + `sneaker${slide.index}`}
                style={{ backgroundColor: slide?.circle_color }}
              ></div>
              {/* <img src={NIKE_AIR_FORCE_1_X_LOUIS_VUTTON} alt="" /> */}
              <div className="card_detail_container">
                <div className="text-end">
                  <h2>{slide?.product_name}</h2>
                  <span>{slide?.product_price_range}</span>
                </div>
                <button>Learn more</button>
              </div>
            </div>
          </a>
        
        })}
      </Slider>

    </div>
  );
};
export default Sliders;
